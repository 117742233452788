
































































import { postController } from '@/modules/common/dialogs/post/post-controller'
import { recruitmentPostController } from '@/modules/recruitment/dialogs/recruitment-post-controller'
import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop({}) postStore!: PostStore
  @Prop({ default: true }) isPostedJobDetailPage

  postController = postController
  recruitmentPostController = recruitmentPostController

  redirectPage() {
    const path = this.isPostedJobDetailPage
      ? `/recruitment/applicants/${this.postStore.post.id}`
      : `/recruitment/posted-jobs/${this.postStore.post.id}`
    this.$router.push(`${path}`)
  }
}
